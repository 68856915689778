import { API_ENTRYPOINT } from '@/config'
import { useSessionStore } from '@/stores/session'
import type { Item } from '@interfaces/item'

export const mercureSubscribe = (
  hubURL: URL,
  topics: string[],
  setData: <T extends Item>(data: T, event: MessageEvent) => void,
): EventSource => {
  const url = new URL(hubURL, API_ENTRYPOINT)

  topics.forEach(topic => url.searchParams.append('topic', topic))

  const eventSource = new EventSource(url.toString())

  eventSource.addEventListener('message', event => {
    setData(JSON.parse(event.data), event)
  })

  return eventSource
}

export const extractHubURL = (response: Response): URL | undefined => {
  if (!response.headers) return undefined
  const linkHeader = response.headers.get('Link')
  if (!linkHeader) return undefined

  const matches = linkHeader.match(/<([^>]+)>;\s+rel=(?:mercure|"[^"]*mercure[^"]*")/)

  return matches && matches[1] ? new URL(matches[1], API_ENTRYPOINT) : undefined
}

export function useMercure() {
  const sessionStore = useSessionStore()
  const { hubURL } = storeToRefs(sessionStore)
  const eventSources = ref<Record<string, EventSource>>({})

  function subscribeToTopics(
    topics: string[],
    storData: <T extends Item>(data: T, event: MessageEvent) => void,
    prefixTopics = true,
  ): EventSource | undefined {
    if (hubURL.value) {
      if (prefixTopics) {
        topics.map(topic => new URL(topic, API_ENTRYPOINT).toString())
      }

      const key = topics.sort().join(';')
      if (!eventSources.value[key]) {
        eventSources.value[key] = mercureSubscribe(hubURL.value, topics, storData)
      } else {
        eventSources.value[key].addEventListener('message', event => {
          storData(JSON.parse(event.data), event)
        })
      }

      return eventSources.value[key]
    } else {
      return undefined
    }
  }
  return { subscribeToTopics, hubURL, eventSources }
}
