import { ContentType } from '@/interfaces/contentType'
import { ProjectDeadline } from '@/interfaces/projectDeadline'
import { Layout, Section, Transition } from '@/interfaces/types'
import { ApiResponse, JSONLD } from '@interfaces/jsonld'

export class WorkflowState extends JSONLD {
  transitions: Record<string, Transition> = {}
  blockers: Record<string, string> = {}
  places: Record<string, Place> = {}
  progress: number = 0
  contentProgress: number = 0
  itemCount: number = 0
  doneItemCount: number = 0
  contentDoneItemCount: number = 0
  actionNeeded: boolean = false
  deadlines: ProjectDeadline[] = []
  subjectId: number = 0
  subjectType: string = ''
  workflowName: string = ''
  workflowDetails: WorkflowDetails[] = []
  contentType?: ContentType
  //eslint-disable-next-line
  forms: Record<string, Record<string, Section>> = {}
  layout: Layout | undefined
  roles: Record<string, string> = {}

  constructor(data: ApiResponse<WorkflowState>) {
    super('WorkflowState')
    Object.assign(this, data)
  }
}
export class WorkflowDetails {
  progress: number = 0
  label: string = ''
  responsibles: string[] = []
  name: string = ''
}

export class Place {
  progress: number = 0
  label: string = ''
  count: number = 0
  name: string = ''
}
