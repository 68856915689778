import { Field, Visualizations } from '@/interfaces/types'
import { JSONLD } from '@interfaces/jsonld'

export class ContentType extends JSONLD {
  name: string = ''
  type: string = 'default'
  icon: string = 'ri-file-line'
  description: string = ''
  workflow: string = ''
  template: string = ''
  visualization: Visualizations = 'editor'
  parts: Record<string, Field> = {}

  constructor(data?: Partial<ContentType>) {
    super('ContentType')
    Object.assign(this, data)
  }
}
