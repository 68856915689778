import { Content, ContentHistory, Part } from '@/interfaces/content'
import { ContentCollection } from '@/interfaces/contentCollection'
import { Participant } from '@/interfaces/participant'
import { ParticipantInvitation } from '@/interfaces/participantInvitation'
import { Participation } from '@/interfaces/participation'
import { Project } from '@/interfaces/project'
import { ProjectDeadline } from '@/interfaces/projectDeadline'
import { User, UserShort } from '@/interfaces/user'
import { UserGroup } from '@/interfaces/userGroup'
import { WorkflowState } from '@/interfaces/workflowState'
import { Notification } from '@/interfaces/notification'
import { ProjectDeadlineContentTypes } from '@/interfaces/projectDeadlineContentTypes'
import { Upload } from '@/interfaces/upload'

export type TwoFactorReturn = { two_factor: boolean; two_factor_complete: boolean }
export const VisualizationArray = ['editor']
export type Visualizations = (typeof VisualizationArray)[number]

export const TypesArray = [
  'Part',
  'Upload',
  'AccessRights',
  'Participant',
  'ParticipantInvitation',
  'ProjectDeadlineContentTypes',
  'ProjectDeadlineContentTypesDto',
  'Participation',
  'Project',
  'User',
  'UserShort',
  'UserGroup',
  'WithWorkflowState',
  'WithWorkflowStateDto',
  'WorkflowTask',
  'WorkflowTaskDto',
  'WorkflowState',
  'ProjectDeadline',
  'ContentCollection',
  'Content',
  'ContentType',
  'ContentTypeDto',
  'Notification',
  'Unknown',
  'hydra:Collection',
  'Upload',
  'ContentHistory',
] as const

export type Types = (typeof TypesArray)[number]

export interface TypeToClassI {
  Part: Part
  Upload: Upload
  ProjectDeadlineContentTypes: ProjectDeadlineContentTypes
  Participant: Participant
  ParticipantInvitation: ParticipantInvitation
  Participation: Participation
  Project: Project
  User: User
  UserShort: UserShort
  UserGroup: UserGroup
  WorkflowState: WorkflowState
  ProjectDeadline: ProjectDeadline
  ContentCollection: ContentCollection
  Content: Content
  Notification: Notification
  ContentHistory: ContentHistory
}

export type TypeToClass = { [key in Types]: key extends keyof TypeToClassI ? TypeToClassI[key] : never }

export const RolesArray = ['ROLE_USER', 'ROLE_ADMIN', 'ROLE_SUPER_ADMIN'] as const
export type UserRoles = (typeof RolesArray)[number]

export type Transition = {
  label?: string
  from: string[]
  to: string[]
  action: string
  form?: string
  action_button?: { label: string }
}

export interface Field {
  name?: string
  label: string
  type: string
  image_name?: string
  values?: Record<string, string>
  multiple?: boolean
  file_types?: string[]
  default?: string
  description?: string
  style?: { break: boolean; width: number; columns?: number }
  required?: boolean
  placeholder?: string
  crop_box?: CropBox
  maxlength?: number
  allowed_tags?: string[]
}
export interface Column {
  width: number
  fields: Record<string, Field>
}

export interface Section {
  label: string
  description?: string
  columns: Column[]
  fields: Record<string, Field>
}

export type FormInputTypes = 'text' | 'textarea' | 'select' | 'checkbox' | 'file' | 'date' | 'datetime-local' | 'time'
export type FormInputDataTypes = string | number | boolean | Date | File | File[] | string[]

export type FormData = Record<string, FormInputDataTypes>
export type SnackbarType = 'success' | 'error' | 'info' | 'warning'
export type Snackbar = { type: SnackbarType; message: string; id?: number; timeout?: number; visible?: boolean }

export type CropData = { x: number; y: number; width: number; height: number }
export type CropBox = { width: number; height: number; overflow?: boolean; background?: string }
export type EditorMode = 'edit' | 'proofread' | 'review' | 'readonly'

export interface Layout {
  tabs?: Tab[]
}
export type Accesses = 'WRITE' | 'READ' | 'ALL'
export interface Button {
  label: string
  outline?: boolean
  transition: string
}

export interface Tab {
  label: string
  forms: string[]
  children: string
  message?: string
  access: Record<string, Record<string, Accesses>>
  buttons?: Button[]
  editor?: { mode: EditorMode }
}
