export const routes = [
  { path: '/', redirect: '/login' },
  {
    path: '/',
    component: () => import('@/layouts/DefaultNoBreadcrumbs.vue'),
    children: [
      {
        path: 'welcome',
        name: 'Welcome',
        component: () => import('@/pages/app/Welcome.vue'),
        meta: { requiresAuth: true, key: 'projects' },
      },

      {
        path: 'content/:contentId',
        name: 'Content',
        component: () => import('@/pages/app/Content.vue'),
        props: (route: { params: { contentId: number } }) => ({
          contentId: route.params.contentId,
        }),
        meta: { requiresAuth: true, key: 'content' },
      },
      {
        path: 'content-collection/:contentCollectionId',
        name: 'Content Collection',
        component: () => import('@/pages/app/ContentCollection.vue'),
        props: (route: { params: { contentCollectionId: number } }) => ({
          contentCollectionId: route.params.contentCollectionId,
        }),
        meta: { requiresAuth: true, key: 'contentCollection' },
      },
      {
        path: 'project/:projectId',
        name: 'Project',
        component: () => import('@/pages/app/Project.vue'),
        props: (route: { params: { projectId: number } }) => ({ projectId: route.params.projectId }),
        meta: { requiresAuth: true, key: 'project' },
      },
    ],
  },
  {
    path: '/',
    component: () => import('@/layouts/Default.vue'),
    children: [
      {
        path: 'account-settings',
        name: 'Account Settings',
        component: () => import('@/pages/settings/AccountSettings.vue'),
        meta: { requiresAuth: true },
      },
    ],
  },
  {
    path: '/',
    component: () => import('@/layouts/Editor.vue'),
    children: [
      {
        path: 'editor/:template',
        name: 'Editor',
        props: (route: { params: { editorGroup: string } }) => ({ template: route.params.template }),
        component: () => import('@/editor/EditorOld.vue'),
      },
    ],
  },
  {
    path: '/',
    component: () => import('@/layouts/Auth.vue'),
    children: [
      {
        path: 'login',
        name: 'Login',
        component: () => import('@/pages/auth/Login.vue'),
        meta: { requiresUnauth: true },
      },
      {
        path: 'forgot-password',
        name: 'Forgot Password',
        component: () => import('@/pages/auth/ForgotPassword.vue'),
        meta: { requiresUnauth: true },
      },
      {
        path: 'password-reset/:token',
        name: 'Reset Password',
        component: () => import('@/pages/auth/ResetPassword.vue'),
        props: (route: { params: { token: string } }) => ({ token: route.params.token }),
      },
      {
        path: 'totp',
        name: 'TOTP',
        component: () => import('@/pages/auth/Totp.vue'),
        meta: { requiresUnauth: true },
      },

      {
        path: 'setup-totp',
        name: 'Setup TOTP',
        component: () => import('@/pages/auth/SetupTotp.vue'),
        meta: { requiresUnauth: true },
      },
      {
        path: 'register/:token',
        name: 'Register',
        component: () => import('@/pages/auth/Register.vue'),
        props: (route: { params: { token: string } }) => ({ token: route.params.token }),
        meta: { requiresUnauth: true },
      },
    ],
  },
  {
    path: '/admin',
    component: () => import('@/layouts/Default.vue'),
    children: [
      {
        path: 'users',
        name: 'Users',
        component: () => import('@/pages/admin/Users.vue'),
        meta: { requiresAuth: true, requiresAdmin: true },
      },
      {
        path: 'user-groups',
        name: 'UserGroups',
        component: () => import('@/pages/admin/UserGroups.vue'),
        meta: { requiresAuth: true, requiresAdmin: true },
      },
      {
        path: 'projects',
        name: 'Projects',
        component: () => import('@/pages/admin/Projects.vue'),
        meta: { requiresAuth: true, requiresAdmin: true },
      },
    ],
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'Error',
    component: () => import('@/pages/Error.vue'),
  },
  {
    path: '/505',
    name: 'Error Not Allowed',
    component: () => import('@/pages/NotAllowed.vue'),
  },
]
